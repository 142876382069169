/<!-- eslint-disable vue/no-v-html */ -->
<template>
  <div class="ai-services_introduction-template">
    <header
      class="header service"
      :class="{ [serviceName]: serviceName, 'banner': true }"
    >
      <div class="banner-slot">
        <slot name="banner" />
      </div>
      <vue-particles
        v-if="particleOptions"
        v-bind="particleOptions"
      />
      <el-row class="content-container">
        <el-col
          :xs="24"
          :md="12"
        >
          <div class="content">
            <divider :variant="`${serviceName}-banner`" />
            <div class="title">
              {{ $props.title }}
            </div>

            <div class="subtitle">
              {{ $props.description }}
            </div>
            <login-button :variant="`${serviceName}-banner`" />
          </div>
        </el-col>
      </el-row>
    </header>
    <div class="content-features">
      <feature-card
        v-for="(feature, index) in features"
        :key="index"
        v-bind="feature"
        :service-name="serviceName"
      />
    </div>
    <why-choose-us
      :introduction="serviceName"
      :login-variant="`${serviceName}-why`"
    />
    <div class="other-services">
      <services-list
        :title="$t('services.otherServices')"
        :subtitle="$t('services.commonDescription')"
      />
    </div>
  </div>
</template>

<script>
import WhyChooseUs from '@/views/components/WhyChooseUs/Index.vue';
import FeatureCard from '@/views/components/FeatureCard/Index.vue';
import ServicesList from '@/views/components/ServicesList/Index.vue';
import Divider from '@/components/Divider/Index.vue';
import LoginButton from '@/views/components/LoginButton/Index.vue';

export default {
  components: {
    Divider,
    LoginButton,
    WhyChooseUs,
    FeatureCard,
    ServicesList,
  },
  props: {
    serviceAbbrev: {
      type: String,
      required: true,
    },
    serviceName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    features: {
      type: Array,
      required: true,
    },
    particleOptions: {
      type: Object,
      default: null,
    },
  },
  methods: {
    onDemoClick() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style lang="scss" scoped>
.ai-services_introduction-template {
  background: #F7F8FF;

  .banner {
    z-index: 1;
    position: relative;
    height: $max-height;
    color: $text-dark-bg;

    .banner-slot {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0.7rem;
      width: 100%;
      height: 100%;

      :first-child {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin-top: auto;
        margin-bottom: auto;
        @include tablet-and-less {
          width: 50%;
        }
      }
    }

    &.service {
      .title {
        font-size: 0.42rem;
      }
    }

    #particles-js {
      position: absolute;
      z-index: -2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .content-container {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 5%;
      }

      .title {
        @include typography-title;
        margin-top: 0.2rem;
        margin-bottom: 0.16rem;
      }

      .subtitle {
        @include typography-subtitle;
        margin-bottom: 0.48rem;
      }
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      top: 0;
      right: 0;
      width: 3.3rem;
      height: 100%;
    }

    &.services_nlp_intro {
      background: linear-gradient(298.88deg, #4B2F01 17.18%, #332000 72.88%);

      &::before {
        background: linear-gradient(301.44deg, #4B2F01 50.5%, #332000 100.9%);
      }
    }

    &.services_vca_intro {
      background: linear-gradient(298.88deg, #521FD1 17.18%, #3A1492 72.88%);

      &::before {
        background: linear-gradient(353.54deg, #521FD1 66.04%, #521FD1 79.44%);
      }
    }

    &.services_aiu_intro {
      background: linear-gradient(298.88deg, #4791FF 17.18%, #003BD1 72.88%);

      &::before {
        background: linear-gradient(345.27deg, #438DFD 38.35%, #0E4CDA 66.31%);
      }
    }

    &.services_asa_intro {
      background: linear-gradient(105.62deg, #007561 12.69%, #14D4B2 82.31%);

      &::before {
        background: linear-gradient(329.62deg, #0CBD9E 66.62%, #13CFAE 100.77%);
      }
    }
  }

  .content-features {
    @include grid-cols-1;
    display: grid;
    gap: 0.18rem 0;
    padding: 0.8rem 0.54rem;

    @include tablet {
      @include grid-cols-2;
    }

    @include lg {
      @include grid-cols-3;
    }
  }

  .other-services {
    padding: 0.9rem 0.87rem;
  }
}
</style>
