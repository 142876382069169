<template>
  <div class="feature-card flex flex-col">
    <div class="feature-card__icon-container">
      <img
        v-if="hot"
        :src="require('@/assets/images/icons/fire.svg')"
        :alt="title"
        class="feature-card__icon-container__hot"
      >
      <img
        :src="icon"
        :alt="title"
        class="feature-card__icon-container__icon"
      >
    </div>
    <h5 class="feature-card__title flex flex-row font-800">
      {{ title }}
      <span class="feature-card__title__languages flex flex-row">
        <language
          v-for="(lang, index) in languages"
          :key="index"
          :lang="lang"
          :service-name="serviceName"
        />
      </span>
    </h5>
    <p class="feature-card__introduction">
      {{ introduction }}
    </p>
    <div class="feature-card__demo">
      <try-demo :link="link" />
    </div>
  </div>
</template>

<script>
import TryDemo from '@/views/components/TryDemo/Index.vue';
import Language from './Language.vue';

export default {
  components: { TryDemo, Language },
  props: {
    hot: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    introduction: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      default: () => [],
    },
    serviceName: {
      type: String,
      default: 'default',
    },
  },
};
</script>

<style lang="scss" scoped>
$icon-size: 0.74rem;
$card-x-padding: 0.32rem;

.feature-card {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0.22rem $card-x-padding;

  &::after {
    content: '';
    position: absolute;
    left: $card-x-padding;
    bottom: 0;
    width: calc(100% - (#{$card-x-padding} * 2));
    border-bottom: 2px solid $color-gray;
  }

  &__icon-container {
    position: relative;

    &__hot {
      position: absolute;
      top: -0.12rem;
      left: -0.12rem;
    }

    &__icon {
      width: $icon-size;
      height: $icon-size;
    }
  }

  &__title {
    align-items: center;
    font-size: 0.2rem;
    line-height: 0.27rem;
    color: $text-primary;
    white-space: nowrap;

    &__languages > * {
      margin-left: 0.08rem;
    }

    @media only screen and (max-width: 1200px) {
      white-space: normal;
    }
  }

  &__introduction {
    margin-top: 0.16rem;
    margin-bottom: 0.25rem;
    font-size: 0.14rem;
    line-height: 0.21rem;
    color: $text-tertiary;
    text-align: justify;
  }

  &__demo {
    display: flex;
    flex: 1;
    align-items: flex-end;
    margin-bottom: 0.25rem;
  }

  &:hover {
    box-shadow: 0px 8px 12px rgba(216, 218, 232, 0.45);
    border-radius: 6px;
    background: linear-gradient(3.4deg, #FFFFFF 0.11%, #F6F8FF 111.64%);
  }
}
</style>
