<template>
  <span
    :class="classes"
    class="language flex flex-center font-700"
  >{{ displayText }}
    <small
      v-if="lang.suffix"
      class="language__suffix"
    >
      {{ lang.suffix }}
    </small>
  </span>
</template>

<script>
export default {
  props: {
    lang: {
      type: [String, Object],
      required: true,
    },
    serviceName: {
      type: String,
      default: 'default',
      validator: (value) => [
        'default',
        'services_nlp_intro',
        'services_vca_intro',
        'services_aiu_intro',
        'services_asa_intro',
      ].indexOf(value) !== -1,
    },
  },
  computed: {
    displayText() {
      const lang = this.lang.code || this.lang;

      if (!this.$methods.isNumeric(lang)) return this.$t(`languages.codes['${lang}']`);

      return this.$t('services.languages', [lang]);
    },
    classes() {
      return [
        this.lang.code || this.lang,
        this.serviceName,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>

.language {
  border-radius: 14px;
  padding: 0.02rem 0.06rem;
  font-size: 0.12rem;
  line-height: 0.19rem;
  text-transform: uppercase;
  white-space: nowrap;

  &.services_nlp_intro {
    color: #734F15;
    background: rgba(250, 155, 14, 0.1);
  }

  &.en {
    color: #3A71FF;
    background: rgba(58, 113, 255, 0.1);
  }

  &.ar {
    color: #31A83E;
    background: rgba(49, 168, 62, 0.1);
  }

  &.zh {
    color: #521FD1;
    background: rgba(217, 202, 255, 0.53);
  }

  &__suffix{
    margin-left: .04rem;
    text-transform: lowercase;
    font-weight: 400;
  }
}
</style>
