<template>
  <router-link
    :to="link"
    class="flex flex-row flex-center try-demo grid grid-cols-2 font-600"
  >
    {{ $t(`button['try demo']`) }}
    <i class="material-icons">east</i>
  </router-link>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
a.try-demo {
  gap: 0.1rem;
  font-size: 0.16rem;
  color: #3A71FF;
}
</style>
